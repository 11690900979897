export const EXAMPLE_SOUND = require('./example.mp3')
export const LUCAS_SOUND = require('./Lucas.mp3')
export const ALWAYSFUNNY_SOUND = require('./Always Funny -Ada.mp3')
export const AWESOME_SOUND = require('./Awesome - Jess.mp3')
export const COFFEE_SOUND = require('./Coffee_ - Jess.mp3')
export const DONTWANTTO_SOUND = require('./Dont Want To - Ada.mp3')
export const HELLYEAH_SOUND = require('./Hell Yeah - Jess.mp3')
export const HIKE_SOUND = require('./Hike - Jess.mp3')
export const IRREGARDLESS_SOUND = require('./irregardless - Jeanette.mp3')
export const ITGOTLATE_SOUND = require('./It Got Late - Jeanette.mp3')
export const LEVIOSA_SOUND = require('./leviosa - Ada.mp3')
export const LOOP_SOUND = require('./Loop - Jess.mp3')
export const NICE_SOUND = require('./Nice - Jess.mp3')
export const POOP_SOUND = require('./Pooooop - Ada.mp3')
export const PP_SOUND = require('./pp - Ada.mp3')
export const SPACE_SOUND = require('./Space - Mike.mp3')
export const THEWEIRD_SOUND = require('./The Weird - Jess.mp3')
export const PATBAKER_SOUND = require('./PatBaker.mp3')
export const A_SOUND = require('./A - Joseph.mp3')
export const BEEPBOP_SOUND = require('./Beep Bop - Jules.mp3')
export const CIGARETTES_SOUND = require('./Cigarettes - Joey.mp3')
export const DUDEEE_SOUND = require('./Dudeee - Joey.mp3')
export const MERRYCHRISTMAS_SOUND = require('./Merry Christmas - Jules.mp3')
export const RAD_SOUND = require('./Rad - Joey.mp3')
export const RIGHTRIGHT_SOUND = require('./Right right - Joey.mp3')
export const SOSTOKED_SOUND = require('./So stoked - Joey.mp3')
export const STARWARS_SOUND = require('./Star Wars - Joseph.mp3')
export const UHWHAT_SOUND = require('./Uh What - Jules.mp3')
export const VIDEOGAMES_SOUND = require('./Video games - Joseph.mp3')
export const WAITWHAT_SOUND = require('./Wait… What_ - Joseph.mp3')
export const WANTANOLDFASHIONED_SOUND = require('./Want an Old Fashioned_ - Joseph.mp3')
export const WHATTHEHECK_SOUND = require('./What the heck - Jules.mp3')
export const WHAT_SOUND = require('./What_ - Joseph.mp3')
export const WHATSFORDINNER_SOUND = require('./What’s for Dinner - Jules.mp3')
export const YO_SOUND = require('./Yo - Joseph.mp3')
export const YOURCABBAGE_SOUND = require('./Your Cabbage - Joey.mp3')
export const CHEWIW_SOUND = require('./Chewie.mp3')
export const DOH_SOUND = require('./D’oh.mp3')
export const DIAREAR_SOUND = require('./Diarear.mp3')
export const EXPLOSIVE_SOUND = require('./Explosive diarrhea.mp3')
export const DAYMARE_SOUND = require('./Daymare.mp3')
export const NICESHIRT_SOUND = require('./Nice shirt.mp3')
export const KYLO_SOUND = require('./Kylo.mp3')

