import {useEffect, useState} from "react";

// This is the fancy audio hook I made with the help of the internet. It's a bit complicated so for now just know it works :P
// Happy to explain how it works further
export const useAudio = url => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => {
      const audios = document.getElementsByTagName('audio');

      console.log("audios", audios)
      for (audio of audios) {
        console.log("should be pausing")
        audio.pause();
      }

      setPlaying(!playing)
    };


    useEffect(() => {
        if (playing) audio.play()
        else {
            audio.pause()
            audio.currentTime = 0;
        }
        },
        [playing]
    );

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    return [playing, toggle];
};
