import './App.css';
import React, {useEffect, useState} from "react";
import {
  A_SOUND,
  ALWAYSFUNNY_SOUND,
  AWESOME_SOUND,
  BEEPBOP_SOUND, CHEWIW_SOUND,
  CIGARETTES_SOUND,
  COFFEE_SOUND, DAYMARE_SOUND, DIAREAR_SOUND,
  DONTWANTTO_SOUND,
  DUDEEE_SOUND,
  EXAMPLE_SOUND, EXPLOSIVE_SOUND,
  HELLYEAH_SOUND,
  HIKE_SOUND,
  IRREGARDLESS_SOUND,
  ITGOTLATE_SOUND, KYLO_SOUND,
  LEVIOSA_SOUND,
  LOOP_SOUND,
  LUCAS_SOUND,
  MERRYCHRISTMAS_SOUND,
  NICE_SOUND, NICESHIRT_SOUND,
  PATBAKER_SOUND,
  POOP_SOUND,
  PP_SOUND,
  RAD_SOUND,
  RIGHTRIGHT_SOUND,
  SOSTOKED_SOUND,
  SPACE_SOUND,
  STARWARS_SOUND,
  THEWEIRD_SOUND,
  UHWHAT_SOUND,
  VIDEOGAMES_SOUND,
  WAITWHAT_SOUND,
  WANTANOLDFASHIONED_SOUND,
  WHATSFORDINNER_SOUND,
  WHATTHEHECK_SOUND, YO_SOUND, YOURCABBAGE_SOUND
} from "./assets/sounds"; // Importing the sounds from the sound index file
import {useAudio} from "./utils/useAudio"; // Importing the useAudio hook I made
import {volumeUpIcon} from "./assets/icons";

function App() {
    const [currentSound, setCurrentSound] = useState(EXAMPLE_SOUND)

  const gridData = [
    [
      {id: '1', label: 'Always Funny', sound: ALWAYSFUNNY_SOUND},
      {id: '2', label: 'Awesome', sound: AWESOME_SOUND},
      {id: '3', label: 'Irregardless', sound: IRREGARDLESS_SOUND},
      {id: '4', label: 'Loop', sound: LOOP_SOUND},
      {id: '5', label: 'Poop', sound: POOP_SOUND},
      {id: '5', label: 'A', sound: A_SOUND},
      {id: '5', label: 'Beep Bop', sound: BEEPBOP_SOUND},
      {id: '5', label: 'Cigarettes', sound: CIGARETTES_SOUND},
      {id: '5', label: 'Dudeee', sound: DUDEEE_SOUND},
      {id: '5', label: 'Merry Christmas', sound: MERRYCHRISTMAS_SOUND},
      {id: '5', label: 'Rad', sound: RAD_SOUND},
      {id: '5', label: 'Right Right', sound: RIGHTRIGHT_SOUND},
      {id: '5', label: 'Daymare', sound: DAYMARE_SOUND},
    ],
    [
      {id: '6', label: 'Coffee?', sound: COFFEE_SOUND},
      {id: '7', label: 'I Dont Want To', sound: DONTWANTTO_SOUND},
      {id: '8', label: 'It Got Late', sound: ITGOTLATE_SOUND},
      {id: '9', label: 'Pat Baker', sound: PATBAKER_SOUND},
      {id: '10', label: 'PP', sound: PP_SOUND},
      {id: '10', label: 'So Stoked', sound: SOSTOKED_SOUND},
      {id: '10', label: 'Star Wars', sound: STARWARS_SOUND},
      {id: '10', label: 'Uh What', sound: UHWHAT_SOUND},
      {id: '10', label: 'Video Games', sound: VIDEOGAMES_SOUND},
      {id: '10', label: 'Wait What', sound: WAITWHAT_SOUND},
      {id: '10', label: 'Old Fashioned?', sound: WANTANOLDFASHIONED_SOUND},
      {id: '10', label: 'Chewie', sound: CHEWIW_SOUND},
      {id: '10', label: 'Kylo', sound: KYLO_SOUND},
    ],
    [
      {id: '11', label: 'Hell Yeah!', sound: HELLYEAH_SOUND},
      {id: '12', label: 'Hike', sound: HIKE_SOUND},
      {id: '13', label: 'Leviosa', sound: LEVIOSA_SOUND},
      {id: '14', label: 'Space', sound: SPACE_SOUND},
      {id: '15', label: 'The Weird', sound: THEWEIRD_SOUND},
      {id: '16', label: 'Nice!', sound: NICE_SOUND},
      {id: '16', label: 'What The Heck', sound: WHATTHEHECK_SOUND},
      {id: '16', label: 'Whats For Dinner?', sound: WHATSFORDINNER_SOUND},
      {id: '16', label: 'Yo', sound: YO_SOUND},
      {id: '16', label: 'Cabbage', sound: YOURCABBAGE_SOUND},
      {id: '16', label: 'Diarear', sound: DIAREAR_SOUND},
      {id: '16', label: 'Explosive Diarear', sound: EXPLOSIVE_SOUND},
      {id: '16', label: 'Nice Shirt', sound: NICESHIRT_SOUND},
    ],
  ]

    const handleCurrentSound = (sound) => {
        setCurrentSound(sound)
    }

  return (
    <div className="App">
      <div className="header">
        <h1 className="topHeader">The Baker Family</h1>
        <h2>Soundboard</h2>
      </div>
      <div className="wrapper">
        {gridData.map((row, index) => (
          <div key={index} className="col">
            {/* Replaced the inner html for the buttons with a new button component, passing in the parameters */}
            {row.map(button => <Button key={`button_${button.id}`} id={button.id} label={button.label} sound={button.sound} currentSound={currentSound} onClick={handleCurrentSound}/>)}
          </div>
        ))}
      </div>
    </div>
  );
}

// New button component :D
// Has properties for id, label, and sound
const Button = ({id, label, sound, currentSound, onClick}) => {

  // This is called a "hook", it's a custom one I made for using audio
  // Basically, it's a function that returns an array, that array contains two variables
  // 'playing' and 'toggle'
  // 'playing': is a variable that is a boolean, it will be true or false depending on if the audio is currently playing or not
  // 'toggle': is a function, you can call this function to start or pause the audio
  //
  // Notice we pass in the sound as a parameter, so the hook function knows which sound we are using.
  //
  // I wouldn't worry too much about how the internals of this function works, typically this is something where you
  // would import, in essence using a library. Ex: when I code faceID I don't do it myself, someone else has already
  // made code for how to use faceID and I just import their code via a library/package
  const [playing, toggle] = useAudio(sound)

    useEffect(() => {
        if (sound !== currentSound && playing) toggle() // Turn off if not the current sound
    }, [currentSound])

    const handleClick = () => {
        toggle()
        onClick(sound)
    }

  // This is now the button and how it looks
  return (
      <div className={`cell ${playing ? 'active' : ''}`} onClick={handleClick}> {/* You can see now onClick we call toggle to toggle the audio */}
        <p>{label}</p>
        {playing && <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="volume-up"
                         className="icon svg-inline--fa fa-volume-up fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 576 512">
          <path fill="currentColor"
                d="M215.03 71.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V88.02c0-21.46-25.96-31.98-40.97-16.97zm233.32-51.08c-11.17-7.33-26.18-4.24-33.51 6.95-7.34 11.17-4.22 26.18 6.95 33.51 66.27 43.49 105.82 116.6 105.82 195.58 0 78.98-39.55 152.09-105.82 195.58-11.17 7.32-14.29 22.34-6.95 33.5 7.04 10.71 21.93 14.56 33.51 6.95C528.27 439.58 576 351.33 576 256S528.27 72.43 448.35 19.97zM480 256c0-63.53-32.06-121.94-85.77-156.24-11.19-7.14-26.03-3.82-33.12 7.46s-3.78 26.21 7.41 33.36C408.27 165.97 432 209.11 432 256s-23.73 90.03-63.48 115.42c-11.19 7.14-14.5 22.07-7.41 33.36 6.51 10.36 21.12 15.14 33.12 7.46C447.94 377.94 480 319.54 480 256zm-141.77-76.87c-11.58-6.33-26.19-2.16-32.61 9.45-6.39 11.61-2.16 26.2 9.45 32.61C327.98 228.28 336 241.63 336 256c0 14.38-8.02 27.72-20.92 34.81-11.61 6.41-15.84 21-9.45 32.61 6.43 11.66 21.05 15.8 32.61 9.45 28.23-15.55 45.77-45 45.77-76.88s-17.54-61.32-45.78-76.86z"></path>
        </svg>} {/* This little bit here is like an if statement, if playing show "playing" otherwise don't show anything, hence it is undefined */}
      </div>
  )
}

export default App;
